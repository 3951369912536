import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDLB-_kzNXiqkjCC6OO23D2BZUaujF1f9Y",
  authDomain: "four-leaf-clover-agro.firebaseapp.com",
  databaseURL: "https://thermal-creek-272615.firebaseio.com",
  projectId: "thermal-creek-272615",
  storageBucket: "thermal-creek-272615.appspot.com",
  messagingSenderId: "691116389741",
  appId: "1:691116389741:web:f442f0035bfb9fb0541664",
  measurementId: "G-N1V0Z3ZHT4",
};

export const myFirebase = firebase.initializeApp(firebaseConfig);
const baseDb = myFirebase.firestore();
export const db = baseDb;
