import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy } from "react";
import "firebase/auth";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages, changeLanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import { verifyAuth } from "./redux/actions";
import { setCurrency } from "./redux/actions/currencyActions";
import AuthRoute from "./AuthRoute";

const Terms = lazy(() => import("./pages/other/TermsAndConditions"));

// home page
const HomeOrganicFood = lazy(() => import("./pages/home/HomeOrganicFood"));

// shop pages
const ShopGridFilter = lazy(() => import("./pages/shop/ShopGridFilter"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));
const ProductTabLeft = lazy(() => import("./pages/shop-product/ProductTabLeft"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const Payment = lazy(() => import("./pages/other/Payment"));
const ConfirmOrder = lazy(() => import("./pages/other/OrderConfirm"));
const Orders = lazy(() => import("./pages/other/Orders"));

const Privacy = lazy(() => import("./pages/other/PrivacyPolicy"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = (props) => {
  useEffect(() => {
    props.dispatch(verifyAuth());
    props.dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
        },
      })
    );
    props.dispatch(changeLanguage("en"));
    props.dispatch(setCurrency("INR"));
  });

  return (
    <ToastProvider placement="bottom-right">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                {/* Homepage */}

                <Route exact path={process.env.PUBLIC_URL + "/"} component={HomeOrganicFood} />

                {/* <Route path={process.env.PUBLIC_URL + "/home-organic-food"} component={HomeOrganicFood} /> */}

                {/* Shop page */}

                <Route path={process.env.PUBLIC_URL + "/shop-grid-filter"} component={ShopGridFilter} />

                {/* Shop product pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/product/:id"}
                  render={(routeProps) => <Product {...routeProps} key={routeProps.match.params.id} />}
                />
                <Route path={process.env.PUBLIC_URL + "/product-tab-left/:id"} component={ProductTabLeft} />

                {/* Other pages */}
                <Route path={process.env.PUBLIC_URL + "/about"} component={About} />
                <Route path={process.env.PUBLIC_URL + "/contact"} component={Contact} />
                <Route path={process.env.PUBLIC_URL + "/my-account"} component={MyAccount} />
                <Route path={process.env.PUBLIC_URL + "/login-register"} component={LoginRegister} />

                <Route path={process.env.PUBLIC_URL + "/cart"} component={Cart} />
                {/* <Route path={process.env.PUBLIC_URL + "/wishlist"} component={Wishlist} /> */}
                <Route exact path={process.env.PUBLIC_URL + "/checkout"} component={Checkout} />
                <AuthRoute path={process.env.PUBLIC_URL + "/payment"} component={Payment} />
                <AuthRoute path={process.env.PUBLIC_URL + "/orderconfirm"} component={ConfirmOrder} />
                <AuthRoute path={process.env.PUBLIC_URL + "/orders"} component={Orders} />
                <Route path={process.env.PUBLIC_URL + "/privacy-policy"} component={Privacy} />
                <Route path={process.env.PUBLIC_URL + "/terms-conditions"} component={Terms} />
                <Route path={process.env.PUBLIC_URL + "/not-found"} component={NotFound} />

                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyAuth: () => {
      dispatch(verifyAuth());
    },
  };
};

export default connect(null, mapDispatchToProps)(multilanguage(App));
