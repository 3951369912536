import { FETCH_PRODUCTS_SUCCESS, PLACE_ORDER_SUCCESS, CONFIRM_ORDER_SUCCESS } from "../actions/productActions";

const initState = {
  products: { data: [] }
};

const productReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload
      };
    case PLACE_ORDER_SUCCESS:
      return {
        ...state,
        payment: action.payload
      };
    case CONFIRM_ORDER_SUCCESS:
      return {
        ...state,
        currentOrder: { header: action.payload, items: action.cartItems }
      };
    default:
      return state;
  }
};

export default productReducer;
