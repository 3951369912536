import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
  RECEIVE_OTP,
  CHECK_USER_SUCCESS,
  GET_CUSTOMER_SUCCESS,
} from "../actions/";

export default (
  state = {
    isLoggingIn: false,
    isLoggingOut: false,
    isVerifying: false,
    loginError: false,
    logoutError: false,
    isAuthenticated: false,
    receivedOTP: false,
    user: {},
    otp: {},
    customer: {},
    token: "",
    tenantId: "",
  },
  action
) => {
  switch (action.type) {
    case RECEIVE_OTP:
      return {
        ...state,
        otp: action.otp,
        receivedOTP: true,
        loginError: false,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
        loginError: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: true,
        user: action.user,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: false,
        loginError: true,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        isLoggingOut: true,
        logoutError: false,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggingOut: false,
        isAuthenticated: false,
        receivedOTP: false,
        customer: {},
        user: {},
        otp: {},
        token: "",
        tenantId: "",
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        isLoggingOut: false,
        logoutError: true,
        token: "",
        tenantId: "",
      };
    case VERIFY_REQUEST:
      return {
        ...state,
        isVerifying: true,
        verifyingError: false,
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        isVerifying: false,
        receivedOTP: false,
      };
    case CHECK_USER_SUCCESS:
      return {
        ...state,
        token: action.user.token,
        tenantId: action.user.tenantid,
      };
    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: action.cusotmer.data[0],
      };
    default:
      return state;
  }
};
