import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import Axios from "axios";
import { verifyUser } from "./redux/actions";

const AuthRoute = ({ auth, verifyUser, component: Component, ...rest }) => {
  const [token, setToken] = useState("");
  useEffect(() => {
    Axios.post(`${process.env.REACT_APP_CONSUMER_API_URL}api/users/GetAccessToken`, {
      userName: auth.user.phoneNumber,
      apikey: `${process.env.REACT_APP_API_KEY}`,
    })
      .then((res) =>
        res.data.map((auth) => {
          if (auth.tenantid !== null) {
            setToken("VALID");
            verifyUser(auth);
          }
        })
      )
      .catch((err) => setToken(err));
  }, [auth.user.phoneNumber, verifyUser]);

  return (
    <Route
      {...rest}
      render={(props) =>
        token === "VALID" ? (
          <Component {...props} />
        ) : (
          <div className="flone-preloader-wrapper">
            <div className="flone-preloader">
              <span></span>
              <span></span>
            </div>
          </div>
        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyUser: (user) => {
      dispatch(verifyUser(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute);
