import { myFirebase } from "../../firebase/firebase";
import firebase from "firebase/app";
import Axios from "axios";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const RECEIVE_OTP = "RECEIVE_OTP";
export const CHECK_USER_SUCCESS = "CHECK_USER_SUCCESS";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";

const requestLogin = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

const receiveOTP = (otp) => {
  return {
    type: RECEIVE_OTP,
    otp,
  };
};

const loginError = (error) => {
  return {
    type: LOGIN_FAILURE,
    error,
  };
};

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};

const receiveLogout = (user) => {
  return {
    type: LOGOUT_SUCCESS,
    user,
  };
};

const logoutError = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

const verifyRequest = () => {
  return {
    type: VERIFY_REQUEST,
  };
};

const verifySuccess = () => {
  return {
    type: VERIFY_SUCCESS,
  };
};

const receiveLogin = (user) => {
  return {
    type: LOGIN_SUCCESS,
    user,
  };
};

export const checkUserSuccess = (user) => {
  return {
    type: CHECK_USER_SUCCESS,
    user,
  };
};

const getCustomerSuccess = (cusotmer) => {
  return {
    type: GET_CUSTOMER_SUCCESS,
    cusotmer,
  };
};

export const sendOTP = (phoneNumber, appVerifier) => (dispatch) => {
  dispatch(requestLogin());
  myFirebase
    .auth()
    .signInWithPhoneNumber(phoneNumber, appVerifier)
    .then((res) => {
      dispatch(receiveOTP(res));
    })
    .catch((error) => {
      //Do something with the error if you want!
      dispatch(loginError());
      console.log(error);
    });
};

export const verifyOTP = (confirmationResult, otp) => (dispatch) => {
  confirmationResult
    .confirm(otp)
    .then((res) => {
      dispatch(receiveLogin(res));
      dispatch(checkUser(res.user.phoneNumber));
    })
    .catch((error) => {
      dispatch(loginError(error));
    });
};

export const verifyUser = (user) => (dispatch) => {
  dispatch(checkUserSuccess(user));
  dispatch(getCustomer(user.tenantid, user.token));
};

export const checkUser = (userName) => (dispatch) => {
  Axios.post(`${process.env.REACT_APP_CONSUMER_API_URL}/api/users/GetAccessToken`, {
    userName: userName,
    apikey: `${process.env.REACT_APP_API_KEY}`,
  }).then((res) => {
    res.data.map((user) => {
      if (user.tenantid !== null) {
        dispatch(checkUserSuccess(user));
        dispatch(getCustomer(user.tenantid, user.token));
      }
    });
  });
};

export const getCustomer = (tenantid, token) => (dispatch) => {
  Axios.get(`${process.env.REACT_APP_CONSUMER_API_URL}api/CustomerProfile/getById_CustomerProfile?CustomerProfileid=${tenantid}`).then(
    (res) => {
      dispatch(getCustomerSuccess(res));
    }
  );
};

export const createCustomer = (user) => (dispatch) => {
  Axios.post(`${process.env.REACT_APP_CONSUMER_API_URL}api/CustomerProfile/Create_Customer_Profile`, {
    customeraccountid: null,
    firstname: user.firstname,
    lastname: user.lastname,
    gender: null,
    mobile: user.phoneNumber,
    email: user.email,
    alternatephone: null,
    username: user.phoneNumber,
    userpassword: "",
    userkey: null,
    firebaseuserid: "",
    city: user.city,
    desiredpickuplocation: user.deliveryPoint,
    streetaddress: user.address,
    pincode: user.postcode,
    GSTnumber: user.gstin,
    deviceProfiles: "[]",
    apikey: `${process.env.REACT_APP_API_KEY}`,
  }).then((res) => {
    dispatch(checkUser(user.phoneNumber));
  });
};

export const updateCustomer = (user, auth, checkout) => (dispatch) => {
  Axios.post(`${process.env.REACT_APP_CONSUMER_API_URL}api/CustomerProfile/Update_Customer_Profile`, {
    customerprofileid: auth.tenantId,
    tenantid: auth.tenantId,
    modifiedUser: auth.tenantId,
    customeraccountid: null,
    firstname: user.firstname,
    lastname: user.lastname,
    gender: null,
    mobile: user.phoneNumber,
    email: user.email,
    alternatephone: null,
    username: user.phoneNumber,
    userpassword: "",
    userkey: null,
    firebaseuserid: "",
    city: user.city,
    desiredpickuplocation: user.deliveryPoint,
    streetaddress: user.address,
    pincode: user.postcode,
    GSTnumber: user.gstin,
    deviceProfiles: "[]",
    apikey: `${process.env.REACT_APP_API_KEY}`,
  }).then((res) => {});
};

export const logoutUser = () => (dispatch) => {
  dispatch(requestLogout());
  myFirebase
    .auth()
    .signOut()
    .then(() => {
      dispatch(receiveLogout({}));
    })
    .catch((error) => {
      dispatch(logoutError());
    });
};

export const verifyAuth = () => (dispatch) => {
  dispatch(verifyRequest());
  firebase.auth().onAuthStateChanged((user) => {
    if (user !== null) {
      dispatch(receiveLogin(user));
    }
    dispatch(verifySuccess());
  });
};
