import Axios from "axios";
import { deleteAllFromCart } from "./cartActions";

export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const PLACE_ORDER_SUCCESS = "PLACE_ORDER";
export const CONFIRM_ORDER_SUCCESS = "CONFIRM_ORDER_SUCCESS";

const fetchProductsSuccess = (products) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products,
});

const placeOrderSuccess = (order) => ({
  type: PLACE_ORDER_SUCCESS,
  payload: order,
});

const confirmOrderSuccess = (order, cartItems) => ({
  type: CONFIRM_ORDER_SUCCESS,
  payload: order,
  cartItems,
});

// fetch products
export const fetchProducts = () => {
  return (dispatch) => {
    Axios.post(`${process.env.REACT_APP_CONSUMER_API_URL}api/ProductCatlog/list`, {
      apikey: `${process.env.REACT_APP_API_KEY}`,
    }).then((products) => {
      dispatch(fetchProductsSuccess(products));
    });
  };
};

export const updateProducts = (products) => {
  return (dispatch) => {
    dispatch(fetchProductsSuccess(products));
  };
};

export const placeOrder = (order) => {
  return (dispatch) => {
    dispatch(placeOrderSuccess(order));
  };
};

export const confirmOrder = (order, cartItems) => {
  return (dispatch) => {
    dispatch(confirmOrderSuccess(order, cartItems));
    dispatch(deleteAllFromCart());
  };
};
